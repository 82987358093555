export default {
  'home.download': 'تحميل',
  'home.chat.problem': 'مشكلة شائعة',
  'home.chat.service': 'الخدمات الإنسانية',
  'home.chat.send': 'يرسل',
  'home.chat.question': 'الرجاء إدخال سؤالك',
  'home.chat.welcome': 'مرحبًا ، شكرًا لك على اهتمامك ، يمكنك التواصل معنا مباشرةً ، أو ترك احتياجاتك ومعلومات الاتصال الخاصة بك مباشرة ، وسوف نتصل بك عبر الهاتف. أي نوع من احتياجات التصميم لديك؟',
  'home.chat.tip-content': 'يرجى إدخال المحتوى',
  'home.chat.tip-chatUser': 'يرجى اختيار كائن الدردشة',
  'home.customized': 'حسب الطلب',
  'home.customized.tip': 'يرجى ترك احتياجاتك ونحن نحن في خدمتك.',
  'home.customized.userName': 'أسماء',
  'home.customized.tel': 'الهاتف',
  'home.customized.email': 'صندوق البريد',
  'home.customized.note': 'رسالة',
  'home.customized.submit': 'تقديم',
  'home.customized.cancel': 'إلغاء',
  'home.customized.validate-require': '« تملأ',
  'home.customized.validate-format': 'خطأ في التنسيق'
}
