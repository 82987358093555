import Vue from 'vue'
import Antd from 'ant-design-vue'
import VueCountUp from 'vue-countupjs'
import App from './App.vue'
import Common from '@/utils/common'
import '@/api/index'
import '@/components/global.component'
import router from '@/router'
import store from '@/store'
import i18n from './locales'

Vue.prototype.$baseUrl = process.env.NODE_ENV == 'development' ? '' : location.origin
Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(VueCountUp) // <v-countup end-value="6000" :options="{suffix: '%'}"></v-countup>
Vue.use(Common)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
