<template>
  <div class="chatItem">
    <div class="servicePic">
      <img src="@/assets/images/chat/profile-service.png" />
      <span style="margin-left:6px;">{{ userName }}&nbsp;&nbsp;{{ time }}</span>
    </div>
    <div class="dialog">{{ msg }}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    userName: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    msg: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.chatItem{
    margin: 10px;
    width: auto;
    display: inline-block;

    .servicePic{
      width: 100%;
      img{
        display: inline-block;
      }
    }

    .dialog{
        margin-left: 10px;
        margin-top: 10px;
        background-color:  #FFFFFF;
        width: auto;
        min-width: 0px;
        max-width: 450px;
        padding: 7px 10px 9px 9px;
        border-radius: 4px;
        font-size: 14px;
        color: #333333;
        line-height: 18px;
        display: inline-block;
    }
}
</style>
