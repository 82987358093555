<template>
  <div class="relative">
    <slot></slot>
    <span class="iconfont gw-left" @click="currentPage--;handlePrevNext()" v-if="showPrev"></span>
    <span class="iconfont gw-right" @click="currentPage++;handlePrevNext()" v-if="showNext"></span>
  </div>
</template>
<script>
export default {
  props: {
    currentNum: {
      type: Number,
      default: 1
    },
    totalNum: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      currentPage: 1,
      totalPage: 0
    }
  },
  watch: {
    currentNum (newVal) {
      this.currentPage = newVal
    },
    totalNum (newVal) {
      this.totalPage = newVal
    }
  },
  computed: {
    showPrev () {
      return this.currentPage !== 1
    },
    showNext () {
      return this.currentPage !== this.totalPage
    }
  },
  methods: {
    handlePrevNext () {
      this.$emit('change', this.currentPage)
    }
  }
}
</script>
<style lang="less" scoped>
.gw-left, .gw-right {
  font-size: 60px;
  position: absolute;
  top: 40%;
  cursor: pointer;
}

@media screen and (min-width:1024px) {
  .gw-left {
    left: -80px;
  }

  .gw-right {
    right: -80px;
  }
}

@media screen and (max-width:1000px) {
  .gw-left, .gw-right {
    display: none;
  }
}
</style>
