import moment from 'moment'

const lansObj = {
  'www.zhongyechisel.com': 'zh-CN',
  'en.zhongyechisel.com': 'en-US',
  'ar.zhongyechisel.com': 'ar-SA',
  'es.zhongyechisel.com': 'es-ES'
}
export const getDefaultLan = function () {
  return lansObj[location.host] || localStorage.getItem('app_language') || 'en-US'
}

export const throttle = function (fn, delay = 200) {
  let timer = null
  return function () {
    const context = this
    const args = arguments
    // 如果已经存在定时器了，则不做处理
    if (!timer) {
      timer = setTimeout(function () {
        fn.apply(context, args)
        timer = null // 完结时，将time改为null
      }, delay)
    }
  }
}

export const debounce = function (fn, delay = 200, immediate = true) {
  let timer = null
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)

    if (immediate) {
      const runNow = !timer
      // 设置定时器，指定时间后设置为null
      timer = setTimeout(function () {
        timer = null
      }, delay)
      // 如果timer已经为null（已到期），则执行函数
      if (runNow) fn.apply(context, args)
    } else {
      // 如果没有设置立即执行，则设置定时器
      timer = setTimeout(function () {
        fn.apply(context, args)
      }, delay)
    }
  }
}

export default {
  install (Vue) {
    Vue.prototype.$setDocumentTitle = function (title) {
      document.title = title
      const ua = navigator.userAgent
      // eslint-disable-next-line
      const regex = /\bMicroMessenger\/([\d\.]+)/
      if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
        const i = document.createElement('iframe')
        i.src = '/favicon.ico'
        i.style.display = 'none'
        i.onload = function () {
          setTimeout(function () {
            i.remove()
          }, 9)
        }
        document.body.appendChild(i)
      }
    }

    Vue.prototype.$json2object = function (obj = {}) {
      const newObj = {}
      Object.keys(obj).forEach(k => {
        try {
          newObj[k] = JSON.parse(obj[k])
        } catch (error) {
          newObj[k] = obj[k]
        }
      })
      return newObj
    }

    function fnListGroup (srcList = [], groupSize = 1, cycleIndex = 1, dstList = []) {
      let pageItem = []
      const cycleStart = groupSize * (cycleIndex - 1)
      for (let i = cycleStart; i < srcList.length; i++) {
        if (i < (groupSize * cycleIndex)) {
          pageItem.push(srcList[i])
          if (i === (srcList.length - 1)) {
            dstList.push(pageItem)
          }
        } else {
          dstList.push(pageItem)
          pageItem = []
          cycleIndex += 1
          fnListGroup(srcList, groupSize, cycleIndex, dstList)
          break
        }
      }
      return dstList
    }

    Vue.prototype.$listGroup = fnListGroup

    // date类型：Moment | Date | string | number
    Vue.prototype.$moment = (date, pattern = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(date).format(pattern)
    }
  }
}
