
import common from './zh-CN/common'
import menu from './zh-CN/menu'
import aboutUs from './zh-CN/aboutUs'
import home from './zh-CN/home'
import proTech from './zh-CN/proTech'
import service from './zh-CN/service'

export default {
  ...common,
  ...menu,
  ...aboutUs,
  ...home,
  ...proTech,
  ...service
}
