<template>
  <div>
    <a-modal :visible="modalShow" @cancel="modalShow=false;$emit('hidden',modalShow)" footer="">
      <div class="phone-chat-main" v-if="isMobile">
        <div class="header">
          <img src="@/assets/images/chat/logo.png" />
        </div>
        <div class="content">
          <a-row>
            <a-col :span="24">
              <div class="left">
                <div class="txt" ref="mainTxt">
                  <div v-for="item in charList" :key="item.id">
                    <ChatItem v-if="item.usertype == 'service'" v-bind="item"></ChatItem>
                    <ChatItemRight v-if="item.usertype == 'client'" v-bind="item"></ChatItemRight>
                  </div>
                </div>
                <div class="question">
                  <button @click="questionShow=true">{{ $t('home.chat.problem') }}</button>
                </div>
                <div class="input">
                  <a-input v-model="inputMsg" type="textarea" @keyup.enter="send()" :placeholder="$t('home.chat.question')" />
                  <button @click="send()">{{ $t('home.chat.send') }}</button>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>

        <div class="question">
          <a-modal :visible="questionShow" @cancel="questionShow=false" footer="" wrap-class-name="question-modal">
            <div class="question-main">
              <a-row>
                <a-col :span="24">
                  <div class="header">
                  </div>
                  <div class="problem">
                    <ul>
                      <li v-for="(item,index) of contentArray" :key="index" @click="getAnswer(item)">
                        <span class="circle"></span>{{ item.name }}？
                      </li>
                    </ul>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-modal>
        </div>
      </div>
      <div class="pc-chat-main" v-else>
        <div class="header">
          <img src="@/assets/images/chat/logo.png" />
        </div>
        <div class="content">
          <a-row>
            <a-col :span="17">
              <div class="left">
                <div class="txt" ref="mainTxt">
                  <div v-for="item in charList" :key="item.id">
                    <ChatItem v-if="item.usertype == 'service'" v-bind="item"></ChatItem>
                    <ChatItemRight v-if="item.usertype == 'client'" v-bind="item"></ChatItemRight>
                  </div>
                </div>
                <div class="input">
                  <a-input v-model="inputMsg" type="textarea" @keyup.enter="send()" :placeholder="$t('home.chat.question')" />
                  <button @click="send()">{{ $t('home.chat.send') }}</button>
                </div>
              </div>
            </a-col>
            <a-col :span="7">
              <div class="right">
                <div class="logo">
                  <img src="@/assets/images/chat/logo-black.png" />
                </div>
                <div class="problem">
                  <span class="pTop">{{ $t('home.chat.problem') }}</span>
                  <ul>
                    <li v-for="(item,index) of contentArray" :key="index" @click="getAnswer(item)">
                      <span class="circle"></span>{{ item.name }}？
                    </li>
                  </ul>
                </div>
                <div class="artifical">
                  <span class="aTop">{{ $t('home.chat.service') }}</span>
                  <img src="@/assets/images/chat/service-black.png" />
                  <span class="aBottom">{{ $t('home.chat.service') }}</span>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ChatItem from './ChatItem.vue'
import ChatItemRight from './ChatItemRight.vue'
import { v4 as uuidv4 } from 'uuid'
import deviceMixin from '@/store/device-mixin'
let socket
const chatUserInfo = { id: 1, name: 'montebel-service' }
export default {
  components: { ChatItem, ChatItemRight },
  mixins: [deviceMixin],
  data () {
    return {
      modalShow: false,
      chatUserName: chatUserInfo.name,
      chatUserId: chatUserInfo.id,
      charList: [
        {
          userName: chatUserInfo.name,
          time: this.$moment(new Date()),
          msg: this.$t('home.chat.welcome'),
          usertype: 'service'
        }
      ],
      inputMsg: '',
      questionShow: false
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    contentArray: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    show: {
      immediate: true,
      handler (show) {
        this.modalShow = show
        if (show) {
          setTimeout(() => { // 不能使用this.$nextTick，因为此时不是真正的dom
            this.go2bottom()
          })
        }
      }
    }
  },
  created () {
    // 获取当前用户名
    this.getUserName()
  },
  methods: {
    /* 根据缓存获取上次聊天的用户名，如果没有则在服务端创建用户并返回 */
    getUserName () {
      const sessionId = localStorage.getItem('sessionId') || uuidv4()
      this.curSessionId = sessionId
      localStorage.setItem('sessionId', sessionId)
      const params = {
        userid: sessionId
      }
      this.$chat.saveUser(params)
        .then((res) => {
          if (res.data) {
            this.currentUserName = res.data.username
            this.currentUserId = res.data.id
            this.getImLog()
            this.initSocket()
          }
        }).catch((err) => {
          console.log(err.message)
        })
    },

    /* 加载历史聊天记录 */
    getImLog () {
      if (this.charList.length === 1) {
        const params = {
          userid: this.curSessionId
        }
        this.$chat.getImLogList(params).then((res) => {
          if (res.data) {
            res.data.forEach(element => {
              this.charList.push({
                id: element.id,
                userName: element.fromUserName,
                userId: element.fromUserId,
                time: element.createTime,
                msg: element.msg,
                usertype: element.fromUserName === this.chatUserName ? 'service' : 'client'
              })
            })
          }
        }).catch((err) => {
          console.log(err.message)
        })
      }
    },

    /* 初始化websocket */
    initSocket () {
      if (typeof (WebSocket) === 'undefined') {
        console.log('您的浏览器不支持WebSocket')
        return
      }

      const _this = this
      const baseUrlHost = process.env.NODE_ENV == 'development' ? 'www.zhongyechisel.com' : location.host
      const socketUrl = `wss://${baseUrlHost}/api/web/imserver/${this.curSessionId}`
      if (socket != null) {
        socket.close()
        socket = null
      }
      // 开启一个websocket服务
      socket = new WebSocket(socketUrl)
      // 打开事件
      socket.onopen = function () {
        console.log('websocket已打开')
      }
      //  浏览器端收消息，获得从服务端发送过来的文本消息
      socket.onmessage = function (msg) {
        const data = JSON.parse(msg.data)
        if (data.users) { // 获取在线人员信息
        // 获取当前连接的所有用户信息，并且排除自身，自己不会出现在自己的聊天列表里
          _this.onlineUserList = data.users.filter(({ user }) => user.username !== _this.currentUserName)
        } else {
          // 如果服务器端发送过来的json数据 不包含 users 这个key，那么发送过来的就是聊天文本json数据
          if (data.from === _this.chatUserName) {
            _this.createContentByRemoteServer(data.text) // 构建消息内容-服务器消息
          }
        }
      }
      // 关闭事件
      socket.onclose = function () {
        console.log('websocket已关闭')
      }
      // 发生了错误事件
      socket.onerror = function () {
        console.log('websocket发生了错误')
      }
    },

    send () {
      if (typeof (WebSocket) === 'undefined') {
        console.log('您的浏览器不支持WebSocket')
        return
      }

      if (!this.chatUserName) {
        this.$message.warning(this.$t('home.chat.tip-chatUser'))
        return
      }

      if (!this.inputMsg) {
        this.$message.warning(this.$t('home.chat.tip-content'))
        return
      }

      const message = { from: this.curSessionId, to: this.chatUserName, text: this.inputMsg }
      socket.send(JSON.stringify(message))
      this.createContentByCurUser(this.inputMsg) // 构建消息内容-本人消息
      this.inputMsg = ''
    },

    /* 模拟服务器发消息（默认问题的答案） */
    sendAnswer (answerMsg) {
      if (typeof (WebSocket) === 'undefined') {
        console.log('您的浏览器不支持WebSocket')
        return
      }

      if (!answerMsg) {
        this.$message.warning(this.$t('home.chat.tip-content'))
        return
      }

      const time = this.$moment(new Date())
      // 保存消息记录
      const data = {
        fromUserId: this.chatUserId,
        fromUserName: this.chatUserName,
        toUserId: this.currentUserId,
        toUserName: this.currentUserName,
        refUserId: this.currentUserId,
        msg: answerMsg,
        readFlag: 0,
        createTime: time,
        updateTime: time
      }
      this.$chat.saveImLog(data)

      this.createContentByRemoteServer(answerMsg) // 构建消息内容-服务器消息
    },

    createContentByCurUser (txt) {
      this.createContent(txt, {
        userId: this.curSessionId,
        userName: this.currentUserName,
        usertype: 'client'
      })
    },
    createContentByRemoteServer (txt) {
      this.createContent(txt, {
        userId: this.chatUserId,
        userName: this.chatUserName,
        usertype: 'service'
      })
    },
    createContent (txt, contentOwnerInfo) {
      this.charList.push({
        id: uuidv4(),
        time: this.$moment(new Date()),
        msg: txt,
        ...contentOwnerInfo
      })
      this.$nextTick(() => {
        this.go2bottom()
      })
    },

    /** 获取默认问题答案 */
    getAnswer (item) {
      this.inputMsg = item.name
      this.send()
      this.sendAnswer(item.value)
      this.questionShow = false
    },

    /* 滚动到聊天内容的底部 */
    go2bottom () {
      const scrollElem = this.$refs.mainTxt
      scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'instant' })
    }
  }
}
</script>

<style lang="less" scoped>

/deep/ .ant-modal-body{
    padding: 0;
}

@media screen and (min-width:1024px){
/deep/ .ant-modal-close-x{
    color: #FFFFFF;
}

/deep/ .ant-modal-content{
  position: relative;
  width: 800px;
  height: 600px;
  display: inline-block;
  left: 50%;
  margin-left: -400px;
}

.pc-chat-main {
    height: 600px;
    width: 800px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 10px;

    .header {
        height: 60px;
        background-color: #376E25;
        border-radius: 4px 4px 0 0;
        text-align: left;
        padding-left: 18px;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 60px;

        img {
            height: 20px;
            vertical-align: middle;
        }
    }

    .content {
        .left {
            border-right: 1px solid #ccc;
            height: 540px;

            .txt {
                height: 360px;
                border-bottom: 1px solid #ccc;
                background: #E8E8E8;
                overflow-y: auto;
            }

            .input {
                height: 180px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                textarea {
                    width: 99%;
                    height: 96%;
                }

                button {
                    width: 60px;
                    height: 30px;
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 30px;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    background-color: #376E25;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
        }

        .right {
            height: 540px;
            text-indent: 1em;

            .logo {
                height: 100px;
                width: 230px;
                padding: 38px 40px;
                border-bottom: 1px solid #E8E8E8;

            }

            .problem{
                height: 260px;

                li{
                    // height: 36px;
                    line-height: 36px;
                    .circle{/** 行内元素无法设置宽高 */
                        width: 12px;
                        height: 12px;
                        margin-right: 10px;
                        background: #FFFFFF;
                        border: 2px solid #376E25;
                        border-radius: 50%;
                        display: inline-block;
                    }
                    font-size: 14px;
                    color: #376E25;
                }

                .pTop{
                    font-size: 16px;
                    font-weight: bold;
                }

            }

            .artifical{
                height: 180px;
                .aTop{
                    font-size: 16px;
                    font-weight: bold;
                }
                img{
                    margin-left: 40px;
                    margin-top: 20px;
                }

                .aBottom{
                    font-size: 12px;
                    display: block;
                }
            }
        }
    }
}
}

@media screen and (max-width:1000px){
  /deep/ .ant-modal-body{
    font-size: 13px;
  }

  /deep/ .ant-modal-content{
    position: relative;
    width:320px;
    height: 520px;
    display: inline-block;
    left: 50%;
    margin-left: -160px;
  }

  /deep/.ant-modal-close-x{
    width: 28px;
    height: 28px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .phone-chat-main{
    width: 320px;
    height: 520px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 10px;
    .header {
        height: 30px;
        background-color: #376E25;
        border-radius: 4px 4px 0 0;
        padding-left: 9px;
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 30px;
        img {
            height: 15px;
            vertical-align: middle;
        }
    }

    .content {
        .left {
            border-right: 1px solid #ccc;
            height: 460px;

            .txt {
                height: 360px;
                // border-bottom: 1px solid #ccc;
                background: #E8E8E8;
                overflow-y: auto;
            }

            .input {
                height: 100px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                textarea{
                  width: 99%;
                  height: 96%;
                }

                button {
                    width: 40px;
                    height: 20px;
                    font-size: 12px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
                    position: absolute;
                    bottom: 8px;
                    right: 8px;
                    background-color: #376E25;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
        }
    }
    .question{
      height: 30px;
      padding-left: 5px;
      background-color: #e8e8e8;
      line-height: 30px;

      button {
        padding: 2px;
        height: 22px;
        font-size: 12px;
        font-family: Arial;
        font-weight: 400;
        line-height: 20px;
        bottom: 8px;
        right: 8px;
        background-color: #FFFFFF;
        border: none;
        border-radius: 10px;
        cursor: pointer;
     }
    }
  }

  /deep/ .question-modal{
    .ant-modal-content{
      position: relative;
      width:300px;
      height: 150px;
      display: inline-block;
      left: 50%;
      margin-left: -150px;
      margin-top: 160px;
    }
  .ant-modal-close-x{
    width: 28px;
    height: 28px;
    line-height: 28px;
    color: #FFFFFF;
  }
  }

  .question-main{
    width: 300px;
    height: 150px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 10px;
    .header {
        height: 30px;
        background-color: #376E25;
        border-radius: 4px 4px 0 0;
        padding-left: 9px;
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 30px;
        img {
            height: 15px;
            vertical-align: middle;
        }
    }

    .problem{
      height: 260px;
      padding-left: 5px;

      li{
        line-height: 36px;
        .circle{/** 行内元素无法设置宽高 */
            width: 12px;
            height: 12px;
            margin-right: 10px;
            background: #FFFFFF;
            border: 2px solid #376E25;
            border-radius: 50%;
            display: inline-block;
        }
        font-size: 14px;
        color: #376E25;
      }

      .pTop{
          font-size: 16px;
          font-weight: bold;
      }

  }
  }
}
</style>
