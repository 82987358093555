export default {
  'home.download': '下载',
  'home.chat.problem': '常见问题',
  'home.chat.service': '人工服务',
  'home.chat.send': '发送',
  'home.chat.question': '请输入您的问题',
  'home.chat.welcome': '您好，感谢您的关注，您可以直接与我们沟通；或者直接留下您的需求和联系方式，我们将通过电话与您联系。你有什么样的设计需求？',
  'home.chat.tip-content': '请输入内容',
  'home.chat.tip-chatUser': '请选择聊天对象',
  'home.customized': '定制',
  'home.customized.tip': '请留下您的需求，我们竭诚为您服务。',
  'home.customized.userName': '姓名',
  'home.customized.tel': '电话',
  'home.customized.email': '邮箱',
  'home.customized.note': '信息',
  'home.customized.submit': '提交',
  'home.customized.cancel': '取消',
  'home.customized.validate-require': '必填信息',
  'home.customized.validate-format': '格式错误'
}
