<template>
  <div class="affix-container">
    <a-affix :offset-top="40">
      <a-space :size="12" direction="vertical">
        <div class="btn-affix" @click="downloadVisible=true">
          <img src="@/assets/images/affix/download.png" />
        </div>
        <div class="btn-affix" @click="chatVisible=true">
          <img src="@/assets/images/affix/consulting.png" />
        </div>
        <div class="btn-affix" @click="go2aboutUs">
          <img src="@/assets/images/affix/company.png" />
        </div>
        <div class="btn-affix" @click="go2other(linkObj.href)" v-if="linkObj">
          <img :src="fileBaseUrl + linkObj.imgUrl" />
        </div>
        <a-back-top :visibility-height="240" class="right-auto bottom-auto">
          <div class="btn-affix" style="background:unset">
            <img src="@/assets/images/affix/back2top.png" />
          </div>
        </a-back-top>
      </a-space>
    </a-affix>
    <!--下载-->
    <Download :show="downloadVisible" :content-array="downloads" @hidden="downloadVisible=false"></Download>
    <!--聊天室-->
    <ChatPage :show="chatVisible" :content-array="questions" @hidden="chatVisible=false"></ChatPage>
    <!--公司-->
    <Company :show="companyVisible" :content-data="companyInfo" @hidden="companyVisible=false"></Company>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fileBaseUrl: this.$baseUrl,
      downloadVisible: false,
      chatVisible: false,
      companyVisible: false,
      companyInfo: {},
      downloads: [],
      questions: [],
      linkObj: undefined
    }
  },
  created () {
    this.getCompanyInfo()
  },
  methods: {
    getCompanyInfo () {
      this.$aboutUs.companyInfo().then(res => {
        if (!res.data) {
          this.$message.warn(this.$t('common.noData'))
          return
        }
        this.companyObj = this.$json2object(res.data)
        const { bizCompanyAbout = {} } = this.companyObj
        this.companyInfo = bizCompanyAbout
        this.downloads = JSON.parse(bizCompanyAbout.download)
        this.questions = JSON.parse(bizCompanyAbout.questions)
        this.linkObj = JSON.parse(bizCompanyAbout.links || '[]')[0]
      })
    },

    go2other (url) {
      window.open(url, '_blank')
    },

    go2aboutUs () {
      this.$router.push({ name: 'AboutUs' })
    }
  }
}
</script>
<style lang="less" scoped>
.affix-container {
  position: fixed;
  right: 10px;
  z-index: 200;
}

.btn-affix {
  cursor: pointer;
  width: 54px;
  height: 54px;
  background: #376E25;
  border-radius: 9px;
}

@media screen and (min-width:1024px) {
  .affix-container {
    bottom: 35%;
  }
}

@media screen and (max-width:1000px) {
  .affix-container {
    bottom: 58%;
  }

  .btn-affix {
    width: 40px;
    height: 40px;
  }
}
</style>
