export default {
  'aboutUs.profile': 'Perfil de empresa',
  'aboutUs.culture': 'Cultura de empresa',
  'aboutUs.qualification': 'Calificación y honor',
  'aboutUs.exhibition': 'La feria',
  'aboutUs.sales': 'Red de ventas',
  'aboutUs.contact': 'Contacta con nosotros',
  'aboutUs.contact.address': 'Dirección',
  'aboutUs.contact.linkMan': 'Contacto',
  'aboutUs.contact.tel': 'Tel.',
  'aboutUs.contact.email': 'Dirección',
  'aboutUs.contact.url': 'Sitio Web'
}
