import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getDefaultLan } from '@/utils/common'

import zhCN from './lang/zh-CN'
import enUS from './lang/en-US'
import esES from './lang/es-ES'
import arSA from './lang/ar-SA'

Vue.use(VueI18n)

const defaultLang = getDefaultLan()

const messages = {
  'zh-CN': zhCN,
  'en-US': enUS,
  'es-ES': esES,
  'ar-SA': arSA
}

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages
})

const lansObj = {
  'zh-CN': 'http://www.zhongyechisel.com',
  'en-US': 'http://en.zhongyechisel.com',
  'ar-SA': 'http://ar.zhongyechisel.com',
  'es-ES': 'http://es.zhongyechisel.com'
}

export function setI18nLanguage (lang) {
  if (i18n.locale !== lang) {
    i18n.locale = lang
    document.querySelector('html').setAttribute('lang', lang)
    if (process.env.NODE_ENV == 'development') {
      location.reload()
    } else {
      Vue.prototype.$baseUrl = lansObj[lang]
      window.open(lansObj[lang], '_self')
    }
  }
}

export default i18n
