import { axios } from '@/utils/request'

export const saveUser = (queryParams) => {
  return axios.get('/api/web/biz/websocket/user', { params: queryParams })
}

export const getImLogList = (queryParams) => {
  return axios.get('/api/web/biz/websocket/imlog', { params: queryParams })
}

export const saveImLog = (data) => {
  return axios.post('/api/web/im/log', data)
}
