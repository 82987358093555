<template>
  <div class="desc relative">
    <div class="ellipsis" :style="showMore?'': ellipsisMore" ref="content">
      <span v-html="content" v-if="isHtml" style="white-space:pre-wrap;"></span>
      <span v-else>{{ content }}</span>
    </div>
    <div class="operation" @click="showMore=!showMore" v-if="operationShow">
      <div v-if="!showMore" class="ope-item">
        <span>{{ $t('common.more') }}</span>
        <span class="iconfont gw-down"></span>
      </div>
      <div v-else class="ope-item">
        <span>{{ $t('common.fold') }}</span>
        <span class="iconfont gw-up"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showLines: {
      type: Number,
      default: 10
    },
    content: {
      type: String,
      default: '暂无内容'
    },
    isHtml: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ellipsisMore () {
      return `-webkit-line-clamp:${this.showLines}`
    }
  },
  watch: {
    content (newV) {
      if (newV) {
        this.$nextTick(() => {
          if (this.countLines(this.$refs.content) < this.showLines) {
            this.operationShow = false
          } else {
            this.operationShow = true
          }
        })
      }
    }
  },
  data () {
    return {
      operationShow: true,
      showMore: false
    }
  },
  methods: {
    countLines (ele) {
      const styles = window.getComputedStyle(ele, null)
      const lh = parseInt(styles.lineHeight, 10)
      const h = parseInt(styles.height, 10)
      const lc = Math.round(h / lh)
      return lc
    }
  }
}
</script>
<style lang="less" scoped>
.desc {
  background: #E8E8E8;
  padding: 20px;
  line-height: 30px;

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .operation {
    margin-top: 10px;
    color: #376E25;
    cursor: pointer;
    display: flex;
    justify-content: center;

    .ope-item {
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 24px;
        animation: upToDownMove 2s infinite;
      }
    }
  }
}
</style>
