export default {
  'home.download': 'DOWNLOAD',
  'home.chat.problem': 'COMMON PROBLEM',
  'home.chat.service': 'ARTIFICAL SERVICES',
  'home.chat.send': 'send',
  'home.chat.question': 'Please enter your question',
  'home.chat.welcome': 'Hello, thank you for your attention, you can communicate with us directly; Or leave your needs and contact information directly, and we will contact you by phone. What kind of design needs do you have?',
  'home.chat.tip-content': 'Please enter the content',
  'home.chat.tip-chatUser': 'Please select a chat partner',
  'home.customized': 'CUSTOMIZED',
  'home.customized.tip': 'Please leave your requirements, we will serve you wholeheartedly.',
  'home.customized.userName': 'Name',
  'home.customized.tel': 'Tel',
  'home.customized.email': 'Email',
  'home.customized.note': 'Note',
  'home.customized.submit': 'Submit',
  'home.customized.cancel': 'Cancel',
  'home.customized.validate-require': 'Required',
  'home.customized.validate-format': 'Format error'
}
