import Vue from 'vue'
import axios from 'axios'
import notification from 'ant-design-vue/es/notification'
import { getDefaultLan } from '@/utils/common'
// 创建 axios 实例
const service = axios.create({
  baseURL: Vue.prototype.$baseUrl,
  timeout: 10000 // 单位毫秒，10秒超时。
})
// 通用错误处理
const err = (error) => {
  notification.error({
    message: '提示',
    description: '出错啦'
  })
  return Promise.reject(error)
}
// request interceptor
service.interceptors.request.use(async (config) => {
  config.headers.language = getDefaultLan()
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  const data = response.data
  if (data.code === 0 || data.code === 200) {
    return Promise.resolve(data)
  } else {
    notification.error({
      message: '提示',
      description: `${data.msg}`
    })
    return Promise.reject(data)
  }
}, err)

export {
  service as axios
}
