<template>
  <div id="mapcontainer"></div>
</template>
<script>
export default {
  props: {
    latLng: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      conter: null, // 地图中心点
      map: null, // 地图
      markerLayer: null // MultiMarker
    }
  },
  watch: {
    latLng (newV) {
      if (newV) {
        this.TMaps(newV)
      }
    }
  },
  methods: {
    TMaps (url) {
      const urls = url.split(',')
      /* eslint-disable */
      this.center = new TMap.LatLng(urls[0],urls[1])
      document.getElementById('mapcontainer').innerHTML = ''
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      this.map = new TMap.Map(document.getElementById('mapcontainer'), {
        center: this.center, // 设置地图中心点坐标
        zoom: 14, // 设置地图缩放级别
        pitch: 0, // 设置俯仰角
        rotation: 45, // 设置地图旋转角度
        baseMap: {			// 底图设置（参数为：VectorBaseMap对象）
          type: 'vector' // 类型：失量底图
        }
      })
      this.map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM) // 移除地图缩放控件
      // 创建并初始化MultiMarker
      this.markerLayer = new TMap.MultiMarker({
        map: this.map, // 指定地图容器
        // 样式定义
        styles: {
          // 创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 35, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // 焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 }
          })
        },
        // 点标记数据数组
        geometries: [{
          id: '1', // 点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          styleId: 'myStyle', // 指定样式id
          position: this.center // 点标记坐标位置
        }]
      })
    /* eslint-disable */
    }
  }
}
</script>
<style lang="less" scoped>
#mapcontainer {
  padding: 0px;
  margin: 0px;
  height: 100%;
}
</style>