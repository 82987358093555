import { setI18nLanguage } from '@/locales'

const TOGGLE_APP_LANGUAGE = 'app_language'
const TOGGLE_MOBILE_TYPE = 'is_mobile'

const app = {
  state: {
    lang: '',
    isMobile: false
  },
  mutations: {
    [TOGGLE_APP_LANGUAGE]: (state, lang) => {
      state.lang = lang
      localStorage.setItem(TOGGLE_APP_LANGUAGE, lang)
    },
    [TOGGLE_MOBILE_TYPE]: (state, isMobile) => {
      state.isMobile = isMobile
    }
  },
  actions: {
    setLang ({ commit }, lang) {
      commit(TOGGLE_APP_LANGUAGE, lang)
      setI18nLanguage(lang)
    },
    setIsMobile ({ commit }, isMobile) {
      commit(TOGGLE_MOBILE_TYPE, isMobile)
    }
  }
}

export default app
