export default {
  'aboutUs.profile': 'Company profile',
  'aboutUs.culture': 'Company culture',
  'aboutUs.qualification': 'Qualification honor',
  'aboutUs.exhibition': 'Exhibition details',
  'aboutUs.sales': 'Sales network',
  'aboutUs.contact': 'Contact us',
  'aboutUs.contact.address': 'Address',
  'aboutUs.contact.linkMan': 'Contact person',
  'aboutUs.contact.tel': 'Tel',
  'aboutUs.contact.email': 'Email',
  'aboutUs.contact.url': 'Website'
}
