<template>
  <a-carousel ref="carouselRef"
              v-bind="$attrs"
              v-on="$listeners"
              @wheel.native="handleWheel"
              @touchstart.native="handleTouchStart"
              @touchmove.native="handleTouchMove"
              @keydown.up.native="$refs.carouselRef.prev()"
              @keydown.down.native="$refs.carouselRef.next()">
    <template slot="prevArrow">
      <slot name="prevArrow"></slot>
    </template>
    <template slot="nextArrow">
      <slot name="nextArrow"></slot>
    </template>

    <slot></slot>
  </a-carousel>
</template>
<script>
import { throttle } from '@/utils/common'
export default {
  inheritAttrs: false,
  data () {
    return {}
  },
  methods: {
    handleWheel (e) {
      if (e.deltaY > 0) {
        // 向下滚动
        this.$refs.carouselRef.next()
      } else {
        this.$refs.carouselRef.prev()
      }
    },
    handleTouchStart (e) {
      this.startY = e.touches[0].clientY
    },
    handleTouchMove: throttle(function (e) {
      const moveY = e.touches[0].clientY
      if (this.startY - moveY > 0) {
        // 向下滚动
        this.$refs.carouselRef.next()
      } else {
        this.$refs.carouselRef.prev()
      }
    })
  }
}
</script>
<style lang="less" scoped>
</style>
