export default {
  'home.download': 'descargar',
  'home.chat.problem': 'preguntas frecuentes',
  'home.chat.service': 'servicios manuales',
  'home.chat.send': 'correo',
  'home.chat.question': 'Por favor, introduzca su pregunta',
  'home.chat.welcome': 'Hola, gracias por su atención, puede comunicarse directamente con nosotros; O deje sus necesidades y datos de contacto directamente, nos pondremos en contacto con usted por teléfono. ¿¿ cuáles son tus necesidades de diseño?',
  'home.chat.tip-content': 'Por favor ingrese el contenido',
  'home.chat.tip-chatUser': 'Por favor seleccione para chatear',
  'home.customized': 'Personalización',
  'home.customized.tip': 'Déjanos tus necesidades, estamos a tu servicio.',
  'home.customized.userName': 'Los nombres',
  'home.customized.tel': 'Tel.',
  'home.customized.email': 'Dirección',
  'home.customized.note': 'Mensaje',
  'home.customized.submit': 'Presentación',
  'home.customized.cancel': 'Cancelación',
  'home.customized.validate-require': 'Obligatorio',
  'home.customized.validate-format': 'Formato incorrecto'
}
