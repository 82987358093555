import { axios } from '@/utils/request'

export const companyInfo = () => {
  return axios.get('/api/web/biz/company/exist')
}

export const exhibitionList = (pageNum) => {
  return axios.get(`/api/web/biz/exhibition/list?pageNum=${pageNum}&pageSize=3`)
}

export const exhibitionInfo = (exhibitionId) => {
  return axios.get(`/api/web/biz/exhibition/${exhibitionId}`)
}
