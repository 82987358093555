<template>
  <div class="content-width mx-auto my-3">
    <!-- 面包屑导航 -->
    <Breadcrumb />
    <div class="content-title" v-if="!$route.meta.hideContentTitle">{{ $t($route.meta.title) }}</div>
    <slot></slot>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
.content-title {
  display: flex;
  justify-content: center;
  font-size: 27px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 40px;
}

@media screen and (min-width:1024px) {
  .content-width {
    width: 60%;
  }
}

@media screen and (max-width:1000px) {
  .content-width {
    width: 90%;
  }
}
</style>
