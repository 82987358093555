<template>
  <div>
    <a-modal :visible="visible" width="800px" @cancel="close()" footer="">
      <div class="main">
        <div class="header">
          <span>COMPANY</span>
        </div>
        <div class="content">
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" style="width:100%;">
            <a-form-item :label="$t('aboutUs.contact.address')">
              {{ contentData.address ||'-' }}
            </a-form-item>
            <a-form-item :label="$t('aboutUs.contact.linkMan')">
              {{ contentData.linkMan ||'-' }}
            </a-form-item>
            <a-form-item :label="$t('aboutUs.contact.tel')">
              {{ contentData.phone ||'-' }}
            </a-form-item>
            <a-form-item :label="$t('aboutUs.contact.email')">
              {{ contentData.email ||'-' }}
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: true
    }
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    contentData: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    // 监听show,visible 随着show变化而变化
    show: {
      immediate: true,
      handler (show) {
        this.visible = show
      }
    }
  },
  methods: {
    close () {
      this.visible = false
      this.$emit('hidden', this.visible)
    }
  }
}
</script>

<style lang="less" scoped>

/deep/ .ant-modal-body{
    padding: 0;
}
/deep/ .ant-modal-close-x{
    color: #FFFFFF;
}

.main {
    height: 600px;
    width: 800px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 10px;

    .header {
        height: 60px;
        background-color: #376E25;
        border-radius: 4px 4px 0 0;
        text-align: left;
        padding-left: 20px;
        line-height: 60px;

        span{
            color:#333333;
            height: 15px;
            font-size: 18px;
            font-family: Arial;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 18px;
        }
    }

    .content {
        height: 540px;
        background: #FFFFFF;
        padding: 20px;
        line-height: 18px;
        text-align: justify;
        display: flex;
        align-items: center;

    }

}
</style>
