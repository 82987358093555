import common from './en-US/common'
import menu from './en-US/menu'
import aboutUs from './en-US/aboutUs'
import home from './en-US/home'
import proTech from './en-US/proTech'
import service from './en-US/service'

export default {
  ...common,
  ...menu,
  ...aboutUs,
  ...home,
  ...proTech,
  ...service
}
