import Vue from 'vue'

const path2name = (path) => {
  path = path.slice(2, -7)
  const reg = /[.-](\w)/g
  return path.replace(reg, ($0, $1) => {
    return $1.toUpperCase()
  })
}

const files = require.context('../api', false, /\.api.js$/)
files.keys().forEach(path => {
  const api = {}
  for (const [methodName, handler] of Object.entries(files(path))) {
    api[methodName] = handler
  }
  const name = path2name(path)
  Vue.prototype[`$${name}`] = api
})
