import { mapState } from 'vuex'
import { getDefaultLan } from '@/utils/common'

const i18nMixin = {
  computed: {
    ...mapState({
      currentLang: state => {
        return state.app.lang || getDefaultLan()
      }
    })
  },
  methods: {
    setLang (lang) {
      this.$store.dispatch('setLang', lang)
    }
  }
}

export default i18nMixin
