<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {}
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (this.$setDocumentTitle(this.$t('common.docTitle')))
      return {}
    }
  },
  created () {
    this.$store.dispatch('setIsMobile', this.isMobile())
  },
  methods: {
    isMobile () {
      const userAgentInfo = navigator.userAgent
      const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
      const getArr = Agents.filter(i => userAgentInfo.includes(i))
      return !!getArr.length
    }
  }
}
</script>
<style lang="less">
@import url('~ant-design-vue/dist/antd.css');
@import url('./tailwind.css');
@import url('./global.less');
@import url('./font.css');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
</style>
