<template>
  <div class="py-3">
    <a-breadcrumb class="cursor-default" :routes="routes">
      <template slot="itemRender" slot-scope="{ route }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ $t(route.meta.title) }}
        </span>
        <router-link v-else :to="{name: route.name||'Home'}">{{ $t(route.meta.title) }}</router-link>
      </template>
    </a-breadcrumb>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    routes () {
      return [...this.data, ...this.$route.matched]
    }
  }
}
</script>
