<template>
  <Carousel class="w-full" arrows autoplay>
    <div slot="prevArrow" class="custom-slick-arrow" style="left: 32px;zIndex: 1">
      <a-icon type="left-circle" />
    </div>
    <div slot="nextArrow" class="custom-slick-arrow" style="right: 32px;">
      <a-icon type="right-circle" />
    </div>
    <div class="flex">
      <img class="w-full mx-auto" src="@/assets/images/home/banner1.png" style="height:225px" />
    </div>
    <div class="flex">
      <img class="w-full mx-auto" src="@/assets/images/home/banner2.png" style="height:225px" />
    </div>
    <div class="flex">
      <img class="w-full mx-auto" src="@/assets/images/home/banner3.png" style="height:225px" />
    </div>
  </Carousel>
</template>
<script>
export default {
  name: 'TopCarousel',
  data () {
    return {}
  }
}
</script>
<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 32px;
  height: 32px;
  font-size: 32px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.25;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
