<template>
  <div class="chatItemRight">
    <div class="clientPic">
      <span style="margin-right:6px;">{{ userName }}&nbsp;&nbsp;{{ time }}</span>
      <img src="@/assets/images/chat/profile-client.png" />
    </div>
    <div class="dialog">{{ msg }}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    userName: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    msg: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.chatItemRight{
    padding: 10px;
    overflow: hidden;
    text-align: right;

    .clientPic{
      float: right;
      img{
        display: inline-block;
      }
    }

    .dialog{
      float: right;
      margin-top: 10px;
      margin-right: 10px;
      background-color: #B2E3B2;
      width: auto;
      min-width: 0px;
      max-width: 450px;
      padding: 7px 10px 9px 9px;
      border-radius: 4px;
      font-size: 14px;
      color: #333333;
      line-height: 18px;
      clear: right;
    }
}
</style>
