
import common from './es-ES/common'
import menu from './es-ES/menu'
import aboutUs from './es-ES/aboutUs'
import home from './es-ES/home'
import proTech from './es-ES/proTech'
import service from './es-ES/service'

export default {
  ...common,
  ...menu,
  ...aboutUs,
  ...home,
  ...proTech,
  ...service
}
