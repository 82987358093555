export default {
  'aboutUs.profile': '公司简介',
  'aboutUs.culture': '公司文化',
  'aboutUs.qualification': '资质荣誉',
  'aboutUs.exhibition': '展会信息',
  'aboutUs.sales': '销售网络',
  'aboutUs.contact': '联系我们',
  'aboutUs.contact.address': '地址',
  'aboutUs.contact.linkMan': '联系人',
  'aboutUs.contact.tel': '电话',
  'aboutUs.contact.email': '邮箱',
  'aboutUs.contact.url': '网址'
}
