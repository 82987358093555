import Vue from 'vue'
import VueRouter from 'vue-router'
import menuRouter from '@/router/menu.router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  // 业务菜单相关
  ...menuRouter,
  {
    path: '*',
    name: 'Exception',
    component: () => import(/* webpackChunkName: "Exception" */ '@/views/exception/Exception')
  }

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes
})
export default router
