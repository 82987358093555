<template>
  <a-layout class="min-h-screen bg-white">
    <a-layout-header class="z-50 p-0 cursor-default bg-white bg-opacity-0" :class="headCss">
      <div class="flex justify-between mx-4">
        <div class="flex-1 flex">
          <h1 class="flex items-center cursor-pointer" style="width:160px;" @click="$router.push({ name: 'Home' })">
            <img class="w-40" src="@/assets/logo.png" alt="logo">
          </h1>
          <div class="pcContainer flex-1 flex justify-center">
            <div class="flex items-center router-text">
              <router-link
                v-for="(item,idx) of menus"
                :key="idx"
                style="margin:0 20px;"
                class="text-base px-2 hover:bg-opacity-80"
                :to="{name: item.name}"
                active-class="active">
                {{ $t(item.meta.title) }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex md:justify-center justify-end" style="width:160px;">
          <div class="phoneContainer mr-6">
            <a-icon type="menu" @click="showDrawer=true" :style="{fontSize: '20px'}" />
          </div>
          <select-lang />
        </div>
      </div>
    </a-layout-header>
    <a-layout-content class="flex flex-col flex-1">
      <!-- 固钉 -->
      <Affix />
      <!-- 路由组件 -->
      <router-view />
    </a-layout-content>
    <!-- 页面底部 -->
    <a-layout-footer class="p-0 cursor-default" :class="footCss">
      <GlobalFooter />
    </a-layout-footer>
    <!--手机端导航-->
    <a-drawer :visible="showDrawer" :closable="false" @close="showDrawer=false">
      <div class="flex flex-col router-text" @click="showDrawer=false">
        <router-link
          v-for="(item,idx) of menus"
          :key="idx"
          class="flex items-center p-4 text-base hover:bg-opacity-80"
          :to="{name: item.name}"
          active-class="active">
          {{ $t(item.meta.title) }}
        </router-link>
      </div>
    </a-drawer>
  </a-layout>
</template>
<script>
import menuRouter from '@/router/menu.router'
export default {
  name: 'RenCaiLayout',
  data () {
    return {
      menus: [],
      showDrawer: false
    }
  },
  computed: {
    headCss () {
      const fixedRoutes = ['Home', 'ChiselDetail']
      return fixedRoutes.includes(this.$route.name) ? 'fixed-header' : ''
    },
    footCss () {
      const fixedRoutes = ['Home', 'ChiselDetail']
      return fixedRoutes.includes(this.$route.name) ? 'fixed-footer' : ''
    }
  },
  created () {
    this.menus = menuRouter[0].children.filter(r => !r.meta.hideMenu)
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.fixed-header {
  position: fixed;
  width: 100vw;
}
.fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
}
.active{
  background: rgba(55,110,37,0.2);
  border-radius: 2px;
}

.router-text {
  font-size: 16px;
  font-weight: bold;
}

@media screen and (min-width:1024px) {
  .phoneContainer {
    display: none;
  }
}

@media screen and (max-width:1000px) {
  .pcContainer {
    display: none;
  }
}
</style>
