
import common from './ar-SA/common'
import menu from './ar-SA/menu'
import aboutUs from './ar-SA/aboutUs'
import home from './ar-SA/home'
import proTech from './ar-SA/proTech'
import service from './ar-SA/service'

export default {
  ...common,
  ...menu,
  ...aboutUs,
  ...home,
  ...proTech,
  ...service
}
