<template>
  <div>
    <a-modal :visible="visible" width="800px" @cancel="close()" footer="">
      <div class="main">
        <div class="header">
          <span>{{ $t('home.download') }}</span>
        </div>
        <div class="content">
          <div class="downloadItem" v-for="(item,index) of contentArray" :key="index">
            <div class="filePic">
              <img :src="getPicType(item.imgUrl)" />
              <span> {{ item.name }} </span>
            </div>
            <div class="button">
              <a :href="item.imgUrl" :target="getTarget(item.imgUrl)">{{ $t('home.download') }}</a>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: true
    }
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    contentArray: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    // 监听show,visible 随着show变化而变化
    show: {
      immediate: true,
      handler (show) {
        this.visible = show
      }
    }
  },

  mounted () {

  },

  created () {

  },

  methods: {
    download () {},
    close () {
      this.visible = false
      this.$emit('hidden', this.visible)
    },

    getPicType (imgUrl) {
      const suffix = imgUrl.substr(imgUrl.lastIndexOf('.') + 1, imgUrl.length).toLowerCase()
      if (suffix === 'pdf') {
        return require('../../assets/images/download/pdf.png')
      } else if (suffix === 'doc') {
        return require('../../assets/images/download/word.png')
      } else if (suffix === 'ppt') {
        return require('../../assets/images/download/ppt.png')
      }
    },

    getTarget (imgUrl) {
      const suffix = imgUrl.substr(imgUrl.lastIndexOf('.') + 1, imgUrl.length).toLowerCase()
      if (suffix === 'pdf') {
        return '_blank'
      } else {
        return '_self'
      }
    }
  }
}
</script>

<style lang="less" scoped>

/deep/ .ant-modal-body{
    padding: 0;
}
/deep/ .ant-modal-close-x{
    color: #FFFFFF;
}

.main {
    height: 600px;
    width: 800px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 10px;

    .header {
        height: 60px;
        background-color: #376E25;
        border-radius: 4px 4px 0 0;
        text-align: left;
        padding-left: 20px;
        line-height: 60px;

        span{
            color:#333333;
            height: 15px;
            font-size: 18px;
            font-family: Arial;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 18px;
        }
    }

    .content {
        border-right: 1px solid #ccc;
        height: 540px;
        background: #E8E8E8;
        padding: 10px 20px;
        overflow-x: hidden;
        overflow-y: auto;
        .downloadItem{
            width: 760px;
            height: 60px;
            margin-bottom: 10px;
            background-color:  #FFFFFF;
            border-radius: 4px;
            line-height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .filePic{
                height: 36px;
                margin: 12px 21px;
                line-height: 36px;
                display: flex;

                span{
                  font-size: 18px;
                  font-family: Adobe Heiti Std;
                  font-weight: normal;
                  color: #333333;
                  padding-left: 10px;
              }
            }

            a{
                margin-right: 21px;
                font-size: 18px;
                font-family: Arial;
                font-weight: 400;
                color: #376E25;
            }

        }
    }

}

@media screen and (min-width:1024px) {
  .main {
    // width: calc((100vw - 20px)/2) ;
  }
}

@media screen and (max-width:1000px) {
  /deep/ .ant-modal-content{
    position: relative;
    width:320px;
    height: 200px;
    display: inline-block;
    left: 50%;
    margin-left: -160px;
    // margin-top: 50%;
  }

  /deep/.ant-modal-close-x{
    width: 25px;
    line-height: 28px;
    color: #FFFFFF;
  }
  .main {
    height: 200px;
    width: 320px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 10px;

    .header {
        height: 30px;
        background-color: #376E25;
        border-radius: 4px 4px 0 0;
        text-align: left;
        padding-left: 10px;
        line-height: 30px;

        span{
            height: 15px;
            font-size: 12px;
            font-family: Arial;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 15px;
        }
    }

    .content {
        border-right: 1px solid #ccc;
        height: 270px;
        background: #E8E8E8;
        padding: 10px 10px;

        .downloadItem{
            width: 300px;
            height: 40px;
            margin-bottom: 10px;
            background-color:  #FFFFFF;
            border-radius: 4px;
            line-height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .filePic{
                height: 30px;
                margin: 5px;
                line-height: 30px;
                display: flex;

                span{
                  font-size: 14px;
                  font-family: Adobe Heiti Std;
                  font-weight: normal;
                  color: #333333;
                  padding-left: 5px;
              }
            }

            a{
                margin-right: 5px;
                font-size: 12px;
                font-family: Arial;
                font-weight: 400;
                color: #376E25;
            }

        }
    }

}
}
</style>
