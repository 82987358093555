import Vue from 'vue'
import GlobalFooter from '@/components/GlobalFooter/GlobalFooter'
import Tabs from '@/components/Tabs/Tabs'
import Carousel from '@/components/Carousel/Carousel'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb'
import ContentArea from '@/components/ContentArea/ContentArea'
import PrevNext from '@/components/PrevNext/PrevNext'
import MoreContent from '@/components/MoreContent/MoreContent'
import Affix from '@/components/Affix/Affix'
import SelectLang from '@/components/SelectLang'
import TencentMap from '@/components/TencentMap/TencentMap'
import TopCarousel from '@/components/TopCarousel/TopCarousel'
import ChatPage from '@/components/Chat/ChatPage'
import Download from '@/components/Download/Download'
import Company from '@/components/Company/Company'

const components = {
  GlobalFooter,
  Tabs,
  Carousel,
  Breadcrumb,
  ContentArea,
  PrevNext,
  MoreContent,
  Affix,
  SelectLang,
  TencentMap,
  TopCarousel,
  ChatPage,
  Download,
  Company
}
Object.keys(components).forEach(key => {
  Vue.component(key, components[key])
  Vue.use(components[key])
})
