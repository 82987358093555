import GlobalLayout from '@/layout/GlobalLayout'
export default [
  {
    path: '/',
    component: GlobalLayout,
    meta: { title: 'menu.home' },
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/menu/Home'),
        meta: { title: 'menu.home' }
      },
      {
        path: 'chiselDetail',
        name: 'ChiselDetail',
        component: () => import(/* webpackChunkName: "ChiselDetail" */ '@/views/menu/ChiselDetail'),
        meta: { title: 'menu.chiselDetail', hideContentTitle: true }
      },
      {
        path: 'otherParts',
        name: 'OtherParts',
        component: () => import(/* webpackChunkName: "OtherParts" */ '@/views/menu/OtherParts'),
        meta: { title: 'menu.otherParts', hideContentTitle: true }
      },
      {
        path: 'prodTech',
        name: 'ProdTech',
        component: () => import(/* webpackChunkName: "ProductionTechnological" */ '@/views/menu/ProdTech'),
        meta: { title: 'menu.prodTech' }
      },
      {
        path: 'service',
        name: 'Service',
        component: () => import(/* webpackChunkName: "Service" */ '@/views/menu/Service'),
        meta: { title: 'menu.service' }
      },
      {
        path: 'aboutUs',
        name: 'AboutUs',
        component: () => import(/* webpackChunkName: "AboutUs" */ '@/views/menu/AboutUs'),
        meta: { title: 'menu.aboutUs' }
      },
      {
        path: 'exhibitionDetail',
        name: 'ExhibitionDetail',
        component: () => import(/* webpackChunkName: "ExhibitionDetail" */ '@/views/menu/ExhibitionDetail'),
        meta: { title: 'menu.exhibitionDetail', hideContentTitle: true, hideMenu: true }
      }
    ]
  }
]
