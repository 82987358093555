export default {
  'aboutUs.profile': 'وصف الشركة',
  'aboutUs.culture': 'ثقافة الشركة',
  'aboutUs.qualification': 'المؤهلات الشرف',
  'aboutUs.exhibition': 'المعرض',
  'aboutUs.sales': 'شبكة المبيعات',
  'aboutUs.contact': 'اتصل بنا',
  'aboutUs.contact.address': 'العنوان',
  'aboutUs.contact.linkMan': 'الاتصال',
  'aboutUs.contact.tel': 'الهاتف',
  'aboutUs.contact.email': 'صندوق البريد',
  'aboutUs.contact.url': 'عنوان الموقع'
}
