<template>
  <a-tabs v-bind="$attrs" v-on="$listeners">
    <template slot="tabBarExtraContent">
      <slot name="tabBarExtraContent"></slot>
    </template>

    <slot></slot>
  </a-tabs>
</template>
<script>
import { throttle } from '@/utils/common'
export default {
  inheritAttrs: false,
  model: {
    prop: 'activeKey',
    event: 'change'
  },
  mounted () {
    document.getElementsByClassName('ant-tabs-bar')[0].addEventListener('touchstart', function (e) {
      this.startX = e.touches[0].clientX
    })
    document.getElementsByClassName('ant-tabs-bar')[0].addEventListener('touchmove', throttle(function (e) {
      const moveX = e.touches[0].clientX
      if (this.startX - moveX > 0) {
        document.getElementsByClassName('ant-tabs-tab-next')[0].click()
      } else {
        document.getElementsByClassName('ant-tabs-tab-prev')[0].click()
      }
    }))
  }
}
</script>
<style lang="less" scoped>
</style>
